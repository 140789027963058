import React, {
    useEffect,
    useState,
    useCallback,
    useImperativeHandle,
    useRef,
    forwardRef,
} from 'react';
import {
    Container,
    Text,
    Row,
    Col,
    Heading,
    Modal,
    className,
} from '@solublestudio/nae-design-system';
import { useTranslate } from '@solublestudio/gatsby-theme-soluble-source';
import style from './styles.module.scss';

import ButtonComponent from '../ButtonComponent';

const LOCAL_STORAGE_KEY = '_theme-picker-tags';
export const LOCAL_STORAGE_COLOR = '_theme-picker-color-data';
const MAX_TAGS = 3;

export function changeDocumentColors(newColor) {
    if (!newColor) {
        ['primary', 'secondary', 'tertiary', 'fourth'].forEach((color) => {
            window.document.documentElement.style.removeProperty(
                `--${color}-color`,
            );
        });

        return;
    }

    ['primary', 'secondary', 'tertiary', 'fourth'].forEach((color) => {
        if (newColor[color]) {
            window.document.documentElement.style.setProperty(
                `--${color}-color`,
                newColor[color],
            );
        }
    });
}

export default forwardRef(function ThemePicker(
    { language, rawColors, rawConcepts, onStatusChange },
    ref,
) {
    const onboardingModalRef = useRef();
    const translation = useTranslate();
    const [concepts, setConcepts] = useState(null);
    const [open, setOpen] = useState(false);
    const [tags, setTags] = useState(null);
    const [activeColor, setActiveColor] = useState(null);
    const [allowBackdropClose, setAllowBackdropClose] = useState(false);

    useEffect(() => {
        const thisConcepts = rawConcepts.edges.map(({ node }) => {
            let relations = {};
            node.colors.forEach((colorConcept) => {
                relations[colorConcept.color.colorId] = colorConcept.r;
            });

            let name = node[language];
            if (
                (language.toLowerCase() === 'es-ca' ||
                    language.toLowerCase() === 'ca-es') &&
                node.cat
            ) {
                name = node.cat;
            }
            if (!name) {
                name = node.en;
            }

            return {
                name,
                id: node.conceptId,
                relations,
            };
        });

        setConcepts(thisConcepts);

        if (typeof window !== 'undefined') {
            let defaultColor = window.localStorage.getItem(LOCAL_STORAGE_COLOR);
            const defaultTags = window.localStorage.getItem(LOCAL_STORAGE_KEY);

            if (defaultTags) {
                let tags = defaultTags.split(',');
                setTags(tags);
            }

            if (defaultColor) {
                defaultColor = JSON.parse(defaultColor);
                setActiveColor(defaultColor.colorId);
                changeDocumentColors(defaultColor);
            }
        }
    }, [
        rawConcepts,
        rawColors,
        language,
        setConcepts,
        setTags,
        setActiveColor,
    ]);

    const setupTags = useCallback(
        (tags) => {
            if (!concepts) {
                return null;
            }

            if (!tags) {
                return null;
            }

            let relations = {};
            let filteredConcepts = concepts.filter(
                (c) => tags.indexOf(c.id) !== -1,
            );
            filteredConcepts.forEach((concept) => {
                Object.keys(concept.relations).forEach((colorId) => {
                    if (!relations[colorId]) {
                        relations[colorId] = 0;
                    }

                    relations[colorId] += concept.relations[colorId];
                });
            });

            if (activeColor && relations[activeColor]) {
                delete relations[activeColor];
            }

            let sortable = [];
            Object.keys(relations).forEach((colorId) => {
                sortable.push([colorId, relations[colorId]]);
            });

            sortable.sort(function (a, b) {
                return b[1] - a[1];
            });

            let maxOccurrences = sortable[0][1];
            let posibleColors = sortable
                .filter((s) => s[1] === maxOccurrences)
                .map((s) => s[0]);
            const randomKey = Math.floor(Math.random() * posibleColors.length);

            const finalColorId = posibleColors[randomKey];

            return finalColorId;
        },
        [concepts, activeColor],
    );

    useEffect(() => {
        onStatusChange(open ? 'open' : null);
        if (!open) {
            setAllowBackdropClose(false);
        }

        if (
            open &&
            typeof window !== 'undefined' &&
            typeof window.gtag !== 'undefined'
        ) {
            window.gtag('event', 'ThemePicker', {
                event_category: 'Click',
                event_label: '_open',
                value: 1,
            });
        }
    }, [open, onStatusChange]);

    useImperativeHandle(ref, () => ({
        open: () => {
            if (tags && tags.length) {
                setOpen(true);
            } else {
                onboardingModalRef.current.toggle();
            }
        },
        close: () => {
            setOpen(false);
        },
    }));

    const onToggleOnboardingModal = useCallback(
        (open) => {
            if (!open) {
                setTimeout(() => {
                    setOpen(true);
                }, 500);
            } else {
                onStatusChange('onboarding');
            }
        },
        [onStatusChange, setOpen],
    );

    const onBackdropClick = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const restoreTags = useCallback(
        (e) => {
            e.preventDefault();
            setTags(null);
            setActiveColor(null);
            changeDocumentColors(null);

            if (
                typeof window !== 'undefined' &&
                typeof window.gtag !== 'undefined'
            ) {
                window.gtag('event', 'ThemePicker', {
                    event_category: 'Click',
                    event_label: '_clear',
                    value: 1,
                });
            }
        },
        [setTags, setActiveColor],
    );

    const toggleTag = useCallback(
        (id) => {
            let newTags = [];
            let trackTag = false;

            if (!tags) {
                newTags.push(id);
                trackTag = true;
            } else if (tags.indexOf(id) === -1) {
                newTags = [...tags, id];
                trackTag = true;
            } else {
                newTags = tags.filter((tag) => tag !== id);
            }

            if (newTags.length > MAX_TAGS) {
                newTags = newTags.slice(
                    newTags.length - MAX_TAGS,
                    newTags.length,
                );
            }

            newTags = newTags.length ? newTags : null;

            if (
                trackTag &&
                typeof window !== 'undefined' &&
                typeof window.gtag !== 'undefined'
            ) {
                const thisConcept = concepts.find((e) => e.id === id);
                window.gtag('event', 'ThemePicker', {
                    event_category: 'Click',
                    event_label: thisConcept ? thisConcept.name : id,
                    value: 1,
                });
            }

            setTags(newTags);
            let colorId = setupTags(newTags);
            setActiveColor(colorId);
            changeDocumentColors(
                rawColors.edges.find(({ node }) => node.colorId === colorId)
                    .node,
            );
        },
        [setTags, setupTags, setActiveColor, tags, rawColors, concepts],
    );

    useEffect(() => {
        if (!tags) {
            window.localStorage.removeItem(LOCAL_STORAGE_KEY);
        } else {
            window.localStorage.setItem(LOCAL_STORAGE_KEY, tags.join(','));
        }
    }, [tags]);

    useEffect(() => {
        if (!activeColor) {
            window.localStorage.removeItem(LOCAL_STORAGE_COLOR);
            return;
        }

        let color = rawColors.edges.find(
            ({ node }) => node.colorId === activeColor,
        ).node;
        window.localStorage.setItem(LOCAL_STORAGE_COLOR, JSON.stringify(color));

        if (
            typeof window !== 'undefined' &&
            typeof window.gtag !== 'undefined'
        ) {
            window.gtag('event', 'ThemePicker', {
                event_category: 'Color',
                event_label: `${activeColor}_${color.primary},${color.secondary},${color.tertiary},${color.fourth}`,
                value: 1,
            });
        }
    }, [activeColor, rawColors]);

    return (
        <>
            <div
                {...className(
                    `${style.backdrop} ${open ? style['backdrop--open'] : ''}`,
                )}
                onClick={onBackdropClick}
                onMouseOver={() => {
                    /*
                    if (allowBackdropClose) {
                        onBackdropClick();
                    }
                    */
                }}
            />
            <div
                {...className(
                    `${style.wrapper} d-flex flex-column ${
                        open ? `${style['wrapper--open']} shadow-lg` : ''
                    }`,
                )}
                onMouseOver={() => {
                    setAllowBackdropClose(true);
                }}>
                <Container>
                    <Row mt={{ xs: 10, lg: 19 }} pb={{ xs: 2, lg: 0 }}>
                        <Col
                            pb={{ xs: 2, lg: 0 }}
                            order={{ lg: 2 }}
                            col={{ lg: 5 }}
                            className="text-lg-right">
                            <button
                                {...className(
                                    `h6 ${style['restore-button']} ${
                                        tags
                                            ? style['restore-button--show']
                                            : ''
                                    }`,
                                )}
                                onClick={restoreTags}>
                                {
                                    translation['theme-picker.dropdown.restore']
                                        .text
                                }
                            </button>
                        </Col>
                        <Col order={{ lg: 1 }} col={{ lg: 7 }}>
                            <Text
                                className={`h6 font-weight-bold ${style.title}`}>
                                {
                                    translation['theme-picker.dropdown.title']
                                        .text
                                }
                            </Text>
                        </Col>
                    </Row>
                </Container>
                {concepts && concepts.length ? (
                    <div {...className(style['concepts-wrapper'])}>
                        <Container>
                            <div
                                {...className(
                                    `${style.concepts} pt-1 pb-2 pt-lg-4 pb-lg-7`,
                                )}>
                                <ButtonComponent
                                    buttonClassName={style.concept}
                                    buttons={concepts.map((concept) => ({
                                        type: 'primary-l',
                                        Tag: 'button',
                                        active:
                                            tags &&
                                            tags.indexOf(concept.id) !== -1,
                                        onClick: () => {
                                            toggleTag(concept.id);
                                        },
                                        label: concept.name,
                                        size: 'sm',
                                    }))}
                                />
                            </div>
                        </Container>
                    </div>
                ) : null}
            </div>
            <Modal
                ref={onboardingModalRef}
                onToggle={onToggleOnboardingModal}
                header={
                    <Heading tag="h4" className={`h4 font-weight-bold`}>
                        {translation['theme-picker.modal.title'].text}
                    </Heading>
                }
                footer={
                    <ButtonComponent
                        button={{
                            label: translation['theme-picker.modal.cta'].text,
                            Tag: 'button',
                            type: 'primary-xl',
                            onClick: () => {
                                onboardingModalRef.current.toggle();
                            },
                        }}
                    />
                }
                disableBackgroundClose={true}
                closeButtonClassName={style['modal-close']}
                dialogClassName={style.modal}
                bodyClassName={style['modal-body']}
                headerClassName={style['modal-header']}
                footerClassName={style['modal-footer']}
                size="lg">
                <Text tag="div" className="body">
                    {translation['theme-picker.modal.text'].html}
                </Text>
            </Modal>
        </>
    );
});
